import React, { FC } from 'react';
import { useSettings } from '@wix/tpa-settings/react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { Text, TextButton } from 'wix-ui-tpa/cssVars';

import { DataHook } from '../../../../../constants/DataHook';
import settingsParams from '../../../settingsParams';
import { SectionHeader } from '../../common/SectionHeader';
import { Divider } from '../../common/Divider';
import { LoginEmail } from '../Account/LoginEmail';
import { classes } from './LoginInfo.st.css';

export const LoginInfo: FC = () => {
  const settings = useSettings();
  const { t } = useTranslation();

  return (
    <div className={classes.root} data-hook={DataHook.LoginInfoSection}>
      <SectionHeader
        title={settings.get(settingsParams.loginInfoSectionTitle)}
        subtitle={settings.get(settingsParams.loginInfoSectionSubtitle)}
      />
      <LoginEmail />
      <Text tagName="div">{t('app.widget.login-password.title')}:</Text>

      <Text tagName="div">⏺ ⏺ ⏺ ⏺ ⏺ ⏺</Text>
      <TextButton>{t('app.widget.login-password.change-button')}</TextButton>
      <Divider />
    </div>
  );
};
